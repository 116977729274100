import { computed, ref, watchEffect } from "vue";
import { useStore } from "vuex";
import { hasMoreTwoClasses } from "@/shared/helpers/getFollowedClasses";
import moment from "moment";
const useFeedback = () => {
  const countFeedbacks = ref(5);
  const store = useStore();
  const getQuestions = ref(false);
  const isLoadingSend = ref(false);
  const feedbackModal = computed(
    () => store.getters["teachers/studentIsShowFeedback"]
  );
  const answers = ref({
    answers: {},
  });

  const questions = ref({});

  const loadServices = async () => {
    await store.dispatch("teachers/allClasses");
    await store.dispatch("teachers/classesTakenThisWeek");
  };

  const seeMore = () => {
    countFeedbacks.value += 5;
  };

  const toggleModal = (isOpen) => {
    store.commit("teachers/studentToggleFeedbackModal", { isOpen });
    
  };

  const handleWriteFeedback = (item) => {
    store.commit("teachers/studentToggleFeedbackModal", {
      isOpen: true,
      mood: "new",
      data: item,
    });
  };

  const handleShowStudentFeedback = (item) => {
    store.commit("teachers/studentToggleFeedbackModal", {
      isOpen: true,
      mood: "edition",
      data: item,
    });
  };

  const clearSubmit = () => {
    answers.value.answers = {};
  };  

  const sendFeedbackNew = async (new_answers) => {
    let res = await store.dispatch("teachers/sendFeedback", {
      payload: { answers: new_answers },
    });
    if (res) {
      store.commit("shared/feedbackOrNoteConfirm", {
        data: { answers: new_answers },
        isOpenConfirm: false,
        isLoading: false,
      });
      clearSubmit();
      await store.dispatch("teachers/dashboardStart");
      loadServices();
      const questions_data = store.getters["teachers/questions"];
      setAnswers(questions_data.data);
    }
  }

  const sendFeedback = async () => {
    isLoadingSend.value = true;
    const feedback = store.getters["teachers/studentIsShowFeedback"];
    const { data } = store.getters["shared/feedbackOrNoteConfirm"];
    const new_answers = [];


    if (Object.entries(data).length === 0) {
      Object.entries(answers.value.answers).forEach(([key, value]) => {
        if (Array.isArray(value))
          value.forEach((answer) => {
            answer.lesson_id = feedback.data?.lesson_id
              ? feedback.data.lesson_id
              : feedback.data.id;
            new_answers.push(answer);
          });
      });
    } else {
      store.commit("shared/feedbackOrNoteConfirm", {
        isLoading: true,
      });
      data.answers.forEach((answer) => {
        answer.lesson_id = feedback.data?.lesson_id
          ? feedback.data.lesson_id
          : feedback.data.id;
        new_answers.push(answer);
      });
    }

    // console.log(feedback.data)
    // console.log(hasMoreTwoClasses(feedback.data))

    // if (hasMoreTwoClasses(feedback.data)) {
    //   const array_answers = [];
    //   feedback.data.arraysIdLessons.forEach((id) => {
    //     const answers = [];
    //     new_answers.forEach((answer) => {
    //       const new_answer = { ...answer };
    //       new_answer.lesson_id = id;
    //       answers.push(new_answer);
    //     });
    //     array_answers.push(answers);
    //   });
    //   array_answers.forEach((answer) => {
    //     if(answer.find(an => an.question_detail_id === 4).comment){
    //       sendFeedbackNew(answer);
    //       isLoadingSend.value = false;
    //       return
    //     }
    //     store.commit('shared/uiNotificationStatus', {
    //       status: "error",
    //       message: 'Please add a comment.',
    //       isOpen: true,
    //     })
    //   });
    //   } else {
      // console.log(new_answers)
      console.log(new_answers)
      if(new_answers.find(an => an.question_detail_id === 4).comment){
          sendFeedbackNew(new_answers);
          isLoadingSend.value = false;
          return
        }
        store.commit('shared/uiNotificationStatus', {
          status: "error",
          message: 'Please add a comment.',
          isOpen: true,
        })
    // }
    
    store.dispatch("shared/resetNotificationStatus");
    isLoadingSend.value = false;
  };

  const allClasses = computed(() => {
    return store.getters["teachers/allClasses"];
  });

  const questions_data = computed(() => {
    return store.getters["teachers/questions"];
  });

  const setAnswers = (questions_data) => {
    questions.value = _.groupBy(questions_data, (question) => question.title);
    Object.entries(questions.value).forEach(([key, value]) => {
      let answers_data = [];
      value.forEach((question, index) => {
        answers_data.push({
          question_detail_id: question.id,
          comment: "",
          lesson_id: null,
          stars: 0,
          topic: question.title,
        });
      });
      answers.value.answers[key] = answers_data;
    });
  };

  const getInfoFeedback = () => {
    return _.groupBy(
      feedbackModal.value.data.feedback,
      (feedback) => feedback.title
    );
  };

  const toggleModalStudent = (data) => {
    const  { isOpen }  = store.getters["teachers/teacherNote"];
    store.commit("teachers/teacherNote", { isOpen : !isOpen , data });
  
  }

  const getInfoFeedbackStudent = () => {
    const  feedbacks = store.getters["teachers/teacherNote"].data.feedback;
    return _.groupBy(feedbacks, feedback => feedback.title);
  }

  const teacherNote = computed(() => {
    return store.getters["teachers/teacherNote"];
  })

  watchEffect(() => {
    if(!feedbackModal.value.isOpen){
      clearSubmit();
      const questions_data = store.getters["teachers/questions"];
      setAnswers(questions_data.data);
    }
    const questions_data = store.getters["teachers/questions"];
    if (questions_data.data.length > 0 && getQuestions.value === false) {
      getQuestions.value = true;
      setAnswers(questions_data.data);
    }
  });

  return {
    getInfoFeedbackStudent,
    teacherNote,
    getInfoFeedback,
    toggleModalStudent,
    allClasses,
    questions_data,
    answers,
    questions,
    seeMore,
    feedbackModal,
    toggleModal,
    handleWriteFeedback,
    countFeedbacks,
    handleShowStudentFeedback,
    sendFeedback,
    loadServices,
    isLoadingSend,
  };
};

export default useFeedback;
