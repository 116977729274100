<template>
  <div class="card-feedback px-4">
    <div class="header-user- w-100">
      <avatar
        :image="photo ? photo : '/assets/images/avatar.png'"
        shape="circle"
      />
    </div>
    <div>
      <h4 v-if="mood === 'new'">{{ names }}</h4>
      <h4
        v-if="mood === 'edition'"
        class="pointer"
        @click="handleShowFeedbackModal()"
      >
        {{ names }}
      </h4>
      <p>{{ formatDate(date, "complete") }}</p>
    </div>
    <button
      v-if="mood === 'new'"
      class="
        btn
        text-white
        bl-shadow
        bg-secondary-1 bg-hover-secondary-1
        py-2
        px-3
        w-100
      "
      @click="handleWriteFeedback"
    >
      Write feedback
    </button>
    <p v-if="mood === 'edition'" class="text-secondary-1">
      Thanks for your feedback.
    </p>
  </div>
  <hr />
</template>

<script>
import formatDate from "@/shared/utils/changeTexts/formatDate";
export default {
  name: "FeedbackWriteComponent",
  props: {
    mood: {
      type: String,
      default: "new",
    },
    handleShowFeedbackModal: {
      type: Function,
      required: true,
    },
    handleWriteFeedback: {
      type: Function,
      required: true,
    },
    names: {
      type: String,
      default: "Student’s Name Last Name",
    },
    date: {
      type: String,
      default: "Thursday , 09 December 2021 at 05:30pm",
    },
    photo: {
      type: String,
      default: "/assets/images/avatar.png",
    },
  },
  setup() {
    return {
      formatDate,
    };
  },
};
</script>


<style lang="scss" scoped>
.card-feedback {
  display: grid;
  grid-template-columns: 10rem 1fr 12rem;
  align-items: center;
  gap: 1rem;
  @media screen and (max-width: 768px) {
    grid-template-columns: 1fr;
    grid-template-rows: auto auto;
    gap: 1rem;
    text-align: center;
  }
}
.header-user- .p-avatar {
  height: 8rem;
  width: 8rem;
}
</style>