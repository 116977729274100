<template>
  <div class="p-4 bg-primary-5 border-r-10 d-flex gap-4 align-items-start">
    <router-link :to="{ name: previousRoute }" class="text-decoration-none">
      <i class="pi pi-arrow-left text-primary-1 fs-4 fw-bold pointer"></i>
    </router-link>
    <div>
      <h3 v-if="!isTeacher" class="text-primary-1 fw-bold first-letter-uppercase">
        {{ forFeedback == 'teachers' ? "Teacher's" : forFeedback }} feedback
      </h3>
       <h3 v-else class="text-primary-1 fw-bold first-letter-uppercase">
        Teacher's notes
      </h3>
      <div class="breadcrumb d-flex gap-1 align-items-center mb-0">
        <router-link :to="{ name: previousRoute }" class="text-decoration-none">
          <p class="text-neutro-1">My {{ forFeedback }}</p>
        </router-link>
        <i class="pi pi-angle-right"></i>
        <p class="fw-bold">View {{ forFeedback }}</p>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "FeedbackBannerComponent",
  props: {
    previousRoute: {
      type: String,
      required: true,
    },
    forFeedback: {
      type: String,
      required: true,
      default: "-",
    },
    isTeacher:{
      type: Boolean,
      default: false,
    }
  },
};
</script>

