<template>
  <feedback-banner-component
    :previousRoute="'teacher-students'"
    :forFeedback="'students'"
    :isTeacher="true"
  />
  <skeleton
    v-if="allClasses.isLoading"
    width="100%"
    height="50vh"
    border-radius="10px"
    class="skeleton-dark"
  />
  <div class="bg-white p-4 mt-4 border-r-10" v-else>
    <p>
      In order to be able to correctly monitor each student, we must leave
      feedback on their progress so that your partners can know their progress
      as well. Please, the more detailed feedback you can give about each
      student, the better the follow-up will be.
    </p>
    <div class="mt-4 d-flex gap-4 flex-column">
      <feedback-write-component
        v-for="item in allClasses.data.slice(0, countFeedbacks)"
        :key="item"
        :photo="item?.student_photo"
        :date="item.lesson_start"
        :names="`${item.student_name} ${item.student_last_name}`"
        :mood="item.send_feedback ? 'edition' : 'new'"
        :handleShowFeedbackModal="(e) => handleShowStudentFeedback(item)"
        :handleWriteFeedback="(e) => handleWriteFeedback(item)"
      />
    </div>
    <button
      v-if="allClasses.data.length > countFeedbacks"
      @click="seeMore"
      class="
        btn
        text-primary-1
        border-primary-1
        p-2
        px-5
        d-flex
        align-items-center
        mx-auto
      "
    >
      <i class="pi pi-plus-circle pe-2 fs-5"></i>
      See more
    </button>
  </div>
</template>

<script>
import FeedbackBannerComponent from "@/shared/components/Feedback/FeedbackBannerComponent";
import FeedbackWriteComponent from "@/shared/components/Feedback/FeedbackWriteComponent";

import useFeedback from "@/modules/teachers/composables/Student/useFeedback";
import { onUpdated } from "vue";

export default {
  name: "StudentsFeedbackView",
  components: {
    FeedbackBannerComponent,
    FeedbackWriteComponent,
  },
  setup() {
    const {
      handleWriteFeedback,
      handleShowStudentFeedback,
      countFeedbacks,
      seeMore,
      loadServices,
      allClasses,
    } = useFeedback();
    loadServices();

    return {
      seeMore,
      handleWriteFeedback,
      countFeedbacks,
      allClasses,
      handleShowStudentFeedback,
    };
  },
};
</script>
